import { AsyncPipe, UpperCasePipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import {
  TuiDataListModule,
  TuiHostedDropdownModule,
  TuiLinkModule,
  TuiSvgModule,
} from '@taiga-ui/core';
import { LangService } from '@lancelot-frontend/core';

@Component({
  selector: 'ffb-lang-select',
  templateUrl: './lang-select.component.html',
  styleUrls: ['./lang-select.component.scss'],
  standalone: true,
  imports: [
    TuiHostedDropdownModule,
    TuiLinkModule,
    TuiLetModule,
    TuiSvgModule,
    TuiDataListModule,
    AsyncPipe,
    UpperCasePipe,
  ],
})
export class LangSelectComponent {
  private langService = inject(LangService);

  open = false;
  availableLangs = this.langService.getAvailableLangs();
  activeLang$ = this.langService.langChanges$;

  getFlagIconName(lang: string) {
    switch (lang) {
      case 'en': {
        return 'GB';
      }
      default: {
        return lang.toUpperCase();
      }
    }
  }

  setActiveLang(lang: string) {
    this.langService.setActiveLang(lang);
    setTimeout(() => {
      this.open = false;
    });
  }
}
