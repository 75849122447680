<ng-container *transloco="let t">
  {{ t('footer.copyright') }}
  |
  @if (app.isNativePlatform) {
    <a tuiLink (click)="appAnalyticsService.openAxeptioCookies()">
      {{ t('nav.cookiePreferences') }}
    </a>
    |
  }
  <a tuiLink routerLink="/legal-notice">
    {{ t('nav.legalNotice') }}
  </a>
  |
  <a tuiLink routerLink="/privacy-policy">
    {{ t('nav.privacyPolicy') }}
  </a>
  |
  <a tuiLink href="https://www.clairepinot.fr/" target="_blank">
    {{ t('footer.designCredits') }}
  </a>
  @if (app.isNativePlatform) {
    |
    <span class="color-text-02"> v{{ releaseName }} ({{ distName }}) </span>
  }
</ng-container>
