<ng-container *transloco="let t">
  <div
    class="row flex flex-row items-center"
    (click)="onClick($event)"
    [ngClass]="{ expanded, 'bg-secondary': expanded }"
    attr.data-test-id="search-results-{{ resource }}-button"
  >
    <div class="block flex-100 flex flex-row items-center">
      <h6 class="tui-text_h6 color-text-01">
        {{ results.nbHits | translocoDecimal }}
      </h6>
      <div class="separator flex-auto"></div>
    </div>
    <h6 class="tui-text_h6 flex-auto" [ngClass]="{ 'color-primary': expanded }">
      @if (resource !== 'helpCategory') {
        {{
          t('resources.' + resource + '', { count: results.nbHits })
            | upperfirst
        }}
      } @else {
        {{ t('nav.help') | upperfirst }}
      }
    </h6>
    <button
      tuiIconButton
      type="button"
      appearance="expand-button"
      [icon]="expanded ? 'tuiIconMinusLarge' : 'tuiIconPlusLarge'"
      (click)="onClick($event)"
    ></button>
  </div>

  <tui-expand
    [expanded]="expanded"
    attr.data-test-id="search-results-{{ resource }}"
  >
    <ng-template tuiExpandContent>
      <!-- Articles -->
      @if (resource === 'article') {
        @for (
          article of (results | as: TSearchResultsArticle).hits;
          track article.id
        ) {
          <a
            [routerLink]="[
              'about-bridge',
              article.article_categories[0].slug,
              article.slug
            ]"
            [state]="{ scrollBehavior: 'GO_TO_TOP' }"
          >
            <div class="row">
              <ffb-search-results-article
                [article]="article"
              ></ffb-search-results-article>
            </div>
          </a>
        }
      }

      <!-- Help categories -->
      @if (resource === 'helpCategory') {
        @for (
          helpCategory of (results | as: TSearchResultsHelpCategory).hits;
          track helpCategory.id
        ) {
          <a routerLink="help" fragment="{{ helpCategory.title | slugify }}">
            <div class="row">
              <ffb-search-results-help-category
                [helpCategory]="helpCategory"
              ></ffb-search-results-help-category>
            </div>
          </a>
        }
      }

      <!-- News -->
      @if (resource === 'newsItem') {
        @for (
          newsItem of (results | as: TSearchResultsNewsItem).hits;
          track newsItem.id
        ) {
          <a [routerLink]="['news', newsItem.slug]">
            <div class="row">
              <ffb-search-results-news-item
                [newsItem]="newsItem"
              ></ffb-search-results-news-item>
            </div>
          </a>
        }
      }

      <!-- Pages -->
      @if (resource === 'page') {
        @for (page of (results | as: TSearchResultsPage).hits; track page.id) {
          <a [routerLink]="['p', page.slug]">
            <div class="row">
              <ffb-search-results-page [page]="page"></ffb-search-results-page>
            </div>
          </a>
        }
      }

      <!-- Practical information -->
      @if (resource === 'practicalInformation') {
        @for (
          practicalInformation of (
            results | as: TSearchResultsPracticalInformation
          ).hits;
          track practicalInformation.id
        ) {
          <a
            [routerLink]="['practical-information', practicalInformation.slug]"
            [state]="{ scrollBehavior: 'GO_TO_TOP' }"
          >
            <div class="row">
              <ffb-search-results-practical-information
                [practicalInformation]="practicalInformation"
              ></ffb-search-results-practical-information>
            </div>
          </a>
        }
      }

      <!-- Press files -->
      @if (resource === 'pressFile') {
        @for (
          pressFile of (results | as: TSearchResultsPressFile).hits;
          track pressFile.id
        ) {
          <a [href]="pressFile.file.url" target="_blank">
            <div class="row">
              <ffb-search-results-press-file [pressFile]="pressFile">
              </ffb-search-results-press-file>
            </div>
          </a>
        }
      }
    </ng-template>
  </tui-expand>
</ng-container>
