import { Component, inject } from '@angular/core';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import { TextZoomService } from '@lancelot-frontend/core';

@Component({
  selector: 'ffb-zoom-controls',
  templateUrl: './zoom-controls.component.html',
  styleUrls: ['./zoom-controls.component.scss'],
  standalone: true,
  imports: [TuiButtonModule, TuiSvgModule],
})
export class ZoomControlsComponent {
  zoom = inject(TextZoomService).zoom;
}
