import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';

export type TBreadcrumb = {
  id: number | string;
  label: PolymorpheusContent;
  loading?: boolean;
  routerLink: (number | string)[] | number | string;
} & NavigationExtras;

@Injectable({
  providedIn: 'root',
})
export class AdminLayoutService {
  breadcrumbs: TBreadcrumb[] = [];
}
