import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiLinkModule } from '@taiga-ui/core';

@Component({
  selector: 'ffb-faciliti-button',
  templateUrl: './faciliti-button.component.html',
  styleUrls: ['./faciliti-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, TuiLinkModule],
})
export class FacilitiButtonComponent {}
