<ng-container *transloco="let t">
  <footer tuiMode="onDark" class="no-print">
    <div class="max-width-5 m-auto px2" *tuiLet="blocks$ | async as blocks">
      <div
        class="nav flex py3"
        [ngClass]="{
          'flex-row': (breakpointObserver.ltMd$ | async) === false,
          'flex-column': (breakpointObserver.ltMd$ | async) === true
        }"
      >
        <div
          class="flex-auto"
          [ngStyle]="{ 'max-width': 100 / (blocks.length + 2) + '%' }"
        >
          <a routerLink="/">
            <div class="logo-container">
              <ffb-logo tuiMode="onDark"></ffb-logo>
            </div>
          </a>
        </div>
        <div class="flex flex-row flex-wrap flex-auto">
          @for (block of blocks; track block.id; let i = $index) {
            <div class="block-of-links flex-auto">
              @for (component of block; track component.id; let j = $index) {
                @if (component.__component === 'navigation.submenu') {
                  <h6 class="block tui-text_h6">
                    {{ component.label }}
                  </h6>
                  @for (link of component.links; track link.id) {
                    @if (
                      link.link.startsWith('http') || link.openInNewTab === true
                    ) {
                      <a
                        tuiLink
                        class="block mt1"
                        [href]="link.link"
                        [target]="
                          link.openInNewTab !== false ? '_blank' : undefined
                        "
                        target="_blank"
                      >
                        {{ link.label }}
                      </a>
                    }
                    @if (
                      !link.link.startsWith('http') &&
                      link.openInNewTab !== true
                    ) {
                      <a
                        *tuiLet="link.link | urlToRouterLink as routerLink"
                        [routerLink]="routerLink.routerLink"
                        [queryParams]="routerLink.queryParams"
                        [fragment]="routerLink.fragment"
                        tuiLink
                        class="block mt1"
                      >
                        {{ link.label }}
                      </a>
                    }
                  }
                }
                @if (component.__component === 'navigation.link') {
                  @if (
                    component.link.startsWith('http') ||
                    component.openInNewTab === true
                  ) {
                    <a
                      tuiLink
                      [ngClass]="{ block: true, mt1: j > 0 }"
                      [href]="component.link"
                      [target]="
                        component.openInNewTab !== false ? '_blank' : undefined
                      "
                      target="_blank"
                    >
                      <h6 class="block tui-text_h6">
                        {{ component.label }}
                      </h6>
                    </a>
                  }
                  @if (
                    !component.link.startsWith('http') &&
                    component.openInNewTab !== true
                  ) {
                    <a
                      *tuiLet="component.link | urlToRouterLink as routerLink"
                      [routerLink]="routerLink.routerLink"
                      [queryParams]="routerLink.queryParams"
                      [fragment]="routerLink.fragment"
                      tuiLink
                      [ngClass]="{ block: true, mt1: j > 0 }"
                    >
                      <h6 class="block tui-text_h6">
                        {{ component.label }}
                      </h6>
                    </a>
                  }
                }
              }
              @if (i === blocks.length - 1) {
                <ffb-assistance-button></ffb-assistance-button>
                <ffb-social-networks></ffb-social-networks>
              }
            </div>
          }
        </div>
      </div>
    </div>
    <div class="max-width-5 m-auto px2">
      <p class="center py3">
        <ffb-legal-notice-links></ffb-legal-notice-links>
      </p>
    </div>
  </footer>
  <div
    class="logos flex flex-row justify-center items-center gap2 py1 no-print"
  >
    <a routerLink="/">
      <tui-svg src="/assets/icon-set/trefle.svg"></tui-svg>
    </a>
    <a href="http://www.eurobridge.org/" target="_blank">
      <img class="ebl-logo" src="/assets/images/ebl-logo.png" />
    </a>
    <a href="http://www.worldbridge.org/" target="_blank">
      <img class="wbf-logo" src="/assets/images/wbf-logo.png" />
    </a>
  </div>
</ng-container>
