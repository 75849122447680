<ng-container *transloco="let t">
  <a
    tuiLink
    [icon]="icon"
    [iconAlign]="iconAlign"
    (click)="generateSupportCode()"
  >
    {{ t('actions.generateSupportCode') }}
  </a>
</ng-container>
