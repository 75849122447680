import { Component, Input, OnChanges } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import {
  TuiDataListModule,
  TuiHostedDropdownModule,
  TuiSvgModule,
} from '@taiga-ui/core';

@Component({
  selector: 'ffb-results-page-select',
  templateUrl: './results-page-select.component.html',
  styleUrls: ['./results-page-select.component.scss'],
  standalone: true,
  imports: [
    TuiHostedDropdownModule,
    TuiSvgModule,
    TuiDataListModule,
    RouterLink,
    TranslocoDirective,
  ],
})
export class ResultsPageSelectComponent implements OnChanges {
  @Input({ required: true }) context!: 'my-results-list' | 'results-list';

  open = false;

  ngOnChanges() {
    this.open = false;
  }
}
