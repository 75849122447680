<ng-container *transloco="let t">
  <header class="no-print">
    <!-- DESKTOP SECONDARY MENU -->
    <div class="secondary-menu-wrapper flex overflow-x-auto" *hide.ltMd="true">
      <div
        class="secondary-menu-content flex flex-auto flex-row items-center justify-end m-auto min-content-width"
      >
        <ffb-header-secondary-menu
          [links]="secondaryLinks"
          tuiMode="onDark"
          data-test-id="header-secondary-menu-desktop"
        ></ffb-header-secondary-menu>
      </div>
    </div>

    <!-- MOBILE MENU -->
    <ng-template
      [ffbDropdownMenu]="mobileMenuOpen"
      (ffbDropdownMenuChange)="toggleMobileMenu()"
      ffbDropdownMenuStyle="z-index: -1"
    >
      <!-- MOBILE MAIN MENU -->
      <ffb-header-main-menu-mobile
        [links]="links"
        data-test-id="header-main-menu-mobile"
      >
      </ffb-header-main-menu-mobile>
      <!-- MOBILE SECONDARY MENU -->
      <div class="mobile-secondary-menu-wrapper">
        <ffb-header-secondary-menu
          [links]="secondaryLinks"
          data-test-id="header-secondary-menu-mobile"
        ></ffb-header-secondary-menu>
      </div>
    </ng-template>

    <!-- SEARCH FORM -->
    <ng-template
      [ffbDropdownMenu]="searchOpen"
      (ffbDropdownMenuChange)="toggleSearch()"
      ffbDropdownMenuStyle="z-index: -1"
    >
      <ffb-app-search-form></ffb-app-search-form>
    </ng-template>

    <div class="main-menu-wrapper flex">
      <div
        class="main-menu-content flex flex-auto flex-row items-center justify-start m-auto"
        [ngClass]="{
          'justify-between': (breakpointObserver.ltMd$ | async) === true
        }"
      >
        <!-- MOBILE MENU BUTTON -->
        <button
          *show.ltMd="true"
          tuiMode="onDark"
          (click)="toggleMobileMenu()"
          tuiIconButton
          type="button"
          size="m"
          appearance="flat"
          [icon]="mobileMenuOpen ? 'tuiIconCloseLarge' : 'tuiIconMenuLarge'"
          data-test-id="menu-button"
        ></button>

        <!-- FFB LOGO -->
        <a class="logo-container mr3" routerLink="/" data-test-id="home-button">
          <ffb-logo></ffb-logo>
        </a>

        <!-- DESKTOP MAIN MENU -->
        <ffb-header-main-menu-desktop
          *hide.ltMd="true"
          class="flex flex-auto justify-end overflow-hidden"
          [links]="links"
          data-test-id="header-main-menu-desktop"
        >
        </ffb-header-main-menu-desktop>

        <div class="flex flex-row gap2 ml2">
          <!-- SEARCH BUTTON -->
          <button
            tuiIconButton
            icon="tuiIconSearch"
            appearance="accent"
            size="m"
            (click)="toggleSearch()"
            data-test-id="search-button"
          ></button>

          <!-- MOBILE USER MENU BUTTON -->
          <ng-container *show.ltLg="true">
            @if (signedInUser?.person?.emailVerified) {
              <tui-hosted-dropdown
                tuiDropdownAlign="right"
                [content]="userDashboardMenu"
                [(open)]="userMenuExpanded"
              >
                <a
                  tuiButton
                  appearance="user-menu-button"
                  shape="rounded"
                  size="m"
                  data-test-id="user-menu-button"
                >
                  <ffb-user-avatar
                    [person]="signedInUser!.person"
                    size="m"
                    data-test-id="user-avatar"
                  ></ffb-user-avatar>
                </a>
              </tui-hosted-dropdown>
            } @else {
              <!-- MOBILE LOGIN BUTTON -->
              <a
                tuiIconButton
                type="button"
                icon="tuiIconUser"
                routerLink="/user/dashboard"
                appearance="mobile-login-button"
                size="m"
                data-test-id="login-button-mobile"
              ></a>
            }
          </ng-container>

          <!-- DESKTOP USER MENU BUTTON -->
          <ng-container *hide.ltLg="true">
            @if (signedInUser?.person?.emailVerified) {
              <tui-hosted-dropdown
                tuiDropdownAlign="right"
                [content]="userDashboardMenu"
                [(open)]="userMenuExpanded"
              >
                <a
                  tuiButton
                  appearance="user-menu-button"
                  shape="rounded"
                  size="m"
                  class="lowercase"
                  data-test-id="user-menu-button"
                >
                  <ffb-user-avatar
                    [person]="signedInUser!.person"
                    size="m"
                    data-test-id="user-avatar"
                  ></ffb-user-avatar>
                  <span class="ml1" data-test-id="user-firstname">
                    {{ signedInUser!.person.firstName }}
                  </span>
                  <tui-svg
                    src="tuiIconChevronDown"
                    class="icon"
                    [class.upside-down]="userMenuExpanded"
                  ></tui-svg>
                </a>
              </tui-hosted-dropdown>
            } @else {
              <!-- DESKTOP LOGIN BUTTON -->
              <a
                tuiButton
                type="button"
                icon="tuiIconUser"
                routerLink="/user/dashboard"
                appearance="primary"
                size="m"
                data-test-id="login-button-desktop"
              >
                {{ t('nav.user.dashboard') }}
              </a>
            }
          </ng-container>
        </div>
      </div>
    </div>
  </header>

  <!-- USER MENU -->
  <ng-template #userDashboardMenu>
    <tui-data-list class="user-dashboard-menu" role="menu">
      <tui-opt-group>
        <a
          tuiOption
          routerLink="/user/dashboard"
          role="menuitem"
          (click)="onClickOnUserMenuLink()"
          data-test-id="user-dashboard-button"
        >
          <tui-svg
            src="/assets/icon-set/user.svg"
            class="color-primary mr1"
          ></tui-svg>
          {{ t('nav.user.dashboard') }}
        </a>
        <a
          tuiOption
          routerLink="/user/results"
          role="menuitem"
          (click)="onClickOnUserMenuLink()"
          data-test-id="user-results-button"
        >
          <tui-svg
            src="/assets/icon-set/cup.svg"
            class="color-primary mr1"
          ></tui-svg>
          {{ t('nav.user.results') }}
        </a>
        <a
          tuiOption
          routerLink="/user/registrations"
          role="menuitem"
          (click)="onClickOnUserMenuLink()"
          data-test-id="user-registrations-button"
        >
          <tui-svg
            src="/assets/icon-set/registrations.svg"
            class="color-primary mr1"
          ></tui-svg>
          {{ t('nav.user.registrations') }}
        </a>
        <a
          tuiOption
          routerLink="/user/profile"
          role="menuitem"
          (click)="onClickOnUserMenuLink()"
          data-test-id="user-profile-button"
        >
          <tui-svg src="tuiIconSettings" class="color-primary mr1"></tui-svg>
          {{ t('nav.user.profile') }}
        </a>
      </tui-opt-group>

      <!-- LOGOUT BUTTON -->
      <tui-opt-group>
        <a
          tuiOption
          role="menuitem"
          (click)="logout()"
          data-test-id="logout-button"
        >
          <tui-svg
            src="/assets/icon-set/logout.svg"
            class="color-primary mr1"
          ></tui-svg>
          {{ t('nav.user.logout') }}
        </a>
      </tui-opt-group>
    </tui-data-list>
  </ng-template>
</ng-container>
