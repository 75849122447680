<tui-hosted-dropdown [content]="dropdown" [(open)]="open">
  <a tuiLink data-test-id="lang-select">
    <div
      class="flex flex-row items-center"
      *tuiLet="activeLang$ | async as activeLang"
    >
      <img
        class="fit-text-height"
        [alt]="activeLang"
        [src]="'/assets/taiga-ui/icons/' + getFlagIconName(activeLang) + '.png'"
      />
      <span class="mx1">
        {{ activeLang | uppercase }}
      </span>
      <tui-svg
        src="tuiIconChevronDown"
        class="icon fit-text"
        [class.upside-down]="open"
      ></tui-svg>
    </div>
  </a>

  <ng-template #dropdown>
    <tui-data-list>
      @for (lang of availableLangs; track lang) {
        <button tuiOption (click)="setActiveLang(lang)">
          <img
            class="fit-text-height"
            [alt]="lang"
            [src]="'/assets/taiga-ui/icons/' + getFlagIconName(lang) + '.png'"
          />
          <span class="ml1">
            {{ lang | uppercase }}
          </span>
        </button>
      }
    </tui-data-list>
  </ng-template>
</tui-hosted-dropdown>
