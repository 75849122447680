<ng-container *transloco="let t">
  <a
    tuiLink
    data-faciliti-popin
    icon="/assets/faciliti/logo.svg"
    iconAlign="left"
    [attr.aria-label]="'FACIL\'iti : ' + t('actions.adaptTheDisplay')"
  >
    {{ t('actions.adaptTheDisplay') }}
  </a>
</ng-container>
