import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiSidebarModule } from '@taiga-ui/addon-mobile';
import {
  ALWAYS_FALSE_HANDLER,
  TuiActiveZoneModule,
  TuiLetModule,
  TuiSwipeService,
} from '@taiga-ui/cdk';
import { TuiButtonModule, TuiLinkModule, TuiSvgModule } from '@taiga-ui/core';
import { TuiBreadcrumbsModule, TuiElasticContainerModule } from '@taiga-ui/kit';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import { Subject, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { AdminService } from '../../../../admin/admin.service';
import { AdminLayoutService } from '../../admin-layout.service';
import { NavigationComponent } from '../navigation/navigation.component';

@Component({
  selector: 'ffb-admin-layout-header',
  standalone: true,
  imports: [
    AsyncPipe,
    RouterLink,
    TranslocoDirective,
    PolymorpheusModule,
    TuiLetModule,
    TuiSidebarModule,
    TuiActiveZoneModule,
    TuiElasticContainerModule,
    TuiBreadcrumbsModule,
    TuiLinkModule,
    TuiButtonModule,
    TuiSvgModule,
    NavigationComponent,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [TuiSwipeService],
})
export class HeaderComponent {
  private router = inject(Router);
  protected adminService = inject(AdminService);
  protected adminLayoutService = inject(AdminLayoutService);
  private readonly _swipes$ = inject(TuiSwipeService);

  private readonly _stream$ = new Subject<boolean>();

  readonly open$ = merge(
    this.router.events.pipe(map(ALWAYS_FALSE_HANDLER)),
    this._stream$,
    this._swipes$.pipe(
      filter(
        (swipe) => swipe.direction === 'left' || swipe.direction === 'right',
      ),
      map((swipe) => swipe.direction === 'right'),
    ),
  ).pipe(startWith(false), distinctUntilChanged());

  outButtonHovered = false;

  onClick(): void {
    this._stream$.next(true);
  }

  onActiveZone(active: boolean): void {
    if (!active) {
      this._stream$.next(false);
    }
  }
}
