<ng-container *transloco="let t">
  <form [formGroup]="searchForm" data-test-id="search-form">
    <div class="flex flex-row justify-between items-end">
      <div class="flex-auto fit">
        <label [tuiLabel]="t('search.form.fields.query.label')">
          <div class="input-wrapper flex flex-row justify-between items-end">
            <tui-input-inline
              formControlName="q"
              class="mt2"
              data-test-id="search-form-input"
            ></tui-input-inline>
            <button
              [ngClass]="{ hidden: !currentQuery }"
              class="clear-button"
              tuiIconButton
              type="button"
              appearance="icon"
              icon="tuiIconClose"
              size="m"
              (click)="clear()"
              data-test-id="search-form-clear-button"
            ></button>
          </div>
        </label>
      </div>
      <button
        tuiIconButton
        type="button"
        appearance="icon"
        icon="tuiIconArrowRightLarge"
        class="ml2"
        [showLoader]="loading"
        data-test-id="search-form-submit-button"
      ></button>
    </div>
  </form>

  @if (newsItems$ | async; as newsItems) {
    @if (newsItems.hits.length) {
      <ffb-app-search-form-results
        resource="newsItem"
        [results]="newsItems"
        [expanded]="expanded === 'newsItem'"
        (toggle)="toggle('newsItem')"
      ></ffb-app-search-form-results>
    }
  }

  @if (articles$ | async; as articles) {
    @if (articles.hits.length) {
      <ffb-app-search-form-results
        resource="article"
        [results]="articles"
        [expanded]="expanded === 'article'"
        (toggle)="toggle('article')"
      ></ffb-app-search-form-results>
    }
  }

  @if (helpCategories$ | async; as helpCategories) {
    @if (helpCategories.hits.length) {
      <ffb-app-search-form-results
        resource="helpCategory"
        [results]="helpCategories"
        [expanded]="expanded === 'helpCategory'"
        (toggle)="toggle('helpCategory')"
      ></ffb-app-search-form-results>
    }
  }

  @if (practicalInformation$ | async; as practicalInformation) {
    @if (practicalInformation.hits.length) {
      <ffb-app-search-form-results
        resource="practicalInformation"
        [results]="practicalInformation"
        [expanded]="expanded === 'practicalInformation'"
        (toggle)="toggle('practicalInformation')"
      ></ffb-app-search-form-results>
    }
  }

  @if (pages$ | async; as pages) {
    @if (pages.hits.length) {
      <ffb-app-search-form-results
        resource="page"
        [results]="pages"
        [expanded]="expanded === 'page'"
        (toggle)="toggle('page')"
      ></ffb-app-search-form-results>
    }
  }

  @if (pressFiles$ | async; as pressFiles) {
    @if (pressFiles.hits.length) {
      <ffb-app-search-form-results
        resource="pressFile"
        [results]="pressFiles"
        [expanded]="expanded === 'pressFile'"
        (toggle)="toggle('pressFile')"
      ></ffb-app-search-form-results>
    }
  }

  @if (error) {
    <div class="center p2 pb4">
      <tui-error [error]="t('errors.unknown')"></tui-error>
    </div>
  }

  @if (empty) {
    <div class="center p2 pb4">
      <p
        class="color-text-03 italic"
        data-test-id="search-form-no-results-message"
      >
        {{ t('resources.result.withCount', { count: 0 }) | upperfirst }}
      </p>
    </div>
  }
</ng-container>
