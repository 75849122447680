<ng-container *transloco="let t">
  <header
    class="full-height flex flex-row items-center pr2"
    *tuiLet="adminService.entities$ | async as entities"
    [class.pl2]="entities?.length === 1"
  >
    <button
      appearance="icon"
      tuiIconButton
      icon="tuiIconMenuLarge"
      class="menu-button"
      (click)="onClick()"
      (tuiActiveZoneChange)="onActiveZone($event)"
    >
      <ffb-admin-layout-navigation
        class="min-full-height"
        *tuiSidebar="!!(open$ | async)"
      ></ffb-admin-layout-navigation>
    </button>
    @if (entities?.length > 1) {
      <tui-elastic-container>
        <a
          tuiButton
          routerLink="/admin"
          [appearance]="outButtonHovered ? 'primary-outline' : 'icon'"
          class="switch-entity-button tui-font-text ml1"
          size="s"
          [class.mr2]="outButtonHovered"
          [state]="{ forceDisplayForm: true }"
          (mouseover)="outButtonHovered = true"
          (mouseout)="outButtonHovered = false"
        >
          <tui-svg src="tuiIconLogOut" class="upside-down"></tui-svg>
          @if (outButtonHovered) {
            {{ t('admin.entitySearchComboBox.switchEntity') }}
          }
        </a>
      </tui-elastic-container>
    }
    <tui-breadcrumbs>
      @for (breadcrumb of adminLayoutService.breadcrumbs; track breadcrumb.id) {
        <a
          *tuiItem
          tuiLink
          [routerLink]="breadcrumb.routerLink"
          [class.tui-skeleton]="breadcrumb.loading"
        >
          <ng-container *polymorpheusOutlet="breadcrumb.label as label">
            {{ label }}
          </ng-container>
        </a>
      }
    </tui-breadcrumbs>
  </header>
</ng-container>
