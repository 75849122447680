import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TRANSLOCO_SCOPE, TranslocoDirective } from '@ngneat/transloco';
import { TuiLinkModule } from '@taiga-ui/core';
import { ExternalLinkDirective } from '@lancelot-frontend/core';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { AppService } from '../../../app.service';
import { AppAnalyticsService } from '../../../app-analytics.service';

@Component({
  selector: 'ffb-legal-notice-links',
  templateUrl: './legal-notice-links.component.html',
  styleUrls: ['./legal-notice-links.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'footer', multi: true }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    TuiLinkModule,
    RouterLink,
    ExternalLinkDirective,
  ],
})
export class LegalNoticeLinksComponent {
  protected app = inject(AppService);
  private environmentService = inject(EnvironmentService);
  protected appAnalyticsService = inject(AppAnalyticsService);

  releaseName = this.environmentService.get('releaseName');
  distName = this.environmentService.get('distName');
}
