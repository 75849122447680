<ng-container *transloco="let t">
  @for (link of links; track link) {
    <!-- External link -->
    @if (link.href) {
      <a tuiLink [href]="link.href" target="_blank" class="nav ml2">
        {{ link.label || t(link.labelTranslationKey) }}
      </a>
    }
    <!-- Simple link -->
    @if (link.routerLink && !link.children?.length) {
      <a tuiLink [routerLink]="link.routerLink" class="nav ml2">
        {{ link.label || t(link.labelTranslationKey) }}
      </a>
    }
  }
  <ffb-assistance-button></ffb-assistance-button>
  <!-- TODO: handle link with children -->
  <ffb-faciliti-button></ffb-faciliti-button>
  <ffb-lang-select></ffb-lang-select>
  <ffb-social-networks></ffb-social-networks>
  @if (textZoomIsAvailable) {
    <ffb-zoom-controls></ffb-zoom-controls>
  }
</ng-container>
