import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiLinkModule } from '@taiga-ui/core';
import {
  ExternalLinkDirective,
  TextZoomService,
} from '@lancelot-frontend/core';
import { AssistanceButtonComponent } from '../../../../components/assistance-button/assistance-button.component';
import { FacilitiButtonComponent } from '../../../../components/faciliti-button/faciliti-button.component';
import { LangSelectComponent } from '../../../../components/lang-select/lang-select.component';
import { SocialNetworksComponent } from '../../../../components/social-networks/social-networks.component';
import { ZoomControlsComponent } from '../../../../components/zoom-controls/zoom-controls.component';
import { THeaderLink } from '../header.component';

@Component({
  selector: 'ffb-header-secondary-menu',
  templateUrl: './header-secondary-menu.component.html',
  styleUrls: ['./header-secondary-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    TuiLinkModule,
    ExternalLinkDirective,
    RouterLink,
    LangSelectComponent,
    SocialNetworksComponent,
    ZoomControlsComponent,
    AssistanceButtonComponent,
    FacilitiButtonComponent,
  ],
})
export class HeaderSecondaryMenuComponent {
  @Input() links!: THeaderLink[];

  textZoomIsAvailable = inject(TextZoomService).isAvailable;
}
