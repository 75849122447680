import { AsyncPipe, NgClass, isPlatformServer } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import {
  IsActiveMatchOptions,
  NavigationEnd,
  Router,
  RouterLink,
} from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiDestroyService } from '@taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiDropdownModule,
  TuiHostedDropdownModule,
  TuiModeModule,
  TuiSvgModule,
} from '@taiga-ui/core';
import { switchMap, takeUntil } from 'rxjs/operators';
import { IMe } from '@lancelot-frontend/api';
import { IMenu, MenuService } from '@lancelot-frontend/cms';
import {
  DropdownMenuDirective,
  LogoComponent,
} from '@lancelot-frontend/components';
import {
  BreakpointObserver,
  LangService,
  ShowHideDirective,
} from '@lancelot-frontend/core';
import { AppService } from '../../../../app.service';
import { AuthenticationService } from '../../../../authentication/authentication.service';
import { SearchFormComponent } from '../../../../search/search-form/search-form.component';
import { UserAvatarComponent } from '../../../../user/components/user-avatar/user-avatar.component';
import { SignedInUserService } from '../../../../user/user.service';
import { HeaderMainMenuDesktopComponent } from './header-main-menu-desktop/header-main-menu-desktop.component';
import { HeaderMainMenuMobileComponent } from './header-main-menu-mobile/header-main-menu-mobile.component';
import { HeaderSecondaryMenuComponent } from './header-secondary-menu/header-secondary-menu.component';

export type THeaderLink = {
  children?: THeaderLink[];
  href?: string;
  label?: string;
  labelTranslationKey?: string;
  menuEntryFromCMS?: boolean;
  routerLink?: string | string[];
  routerLinkActiveOptions?:
    | {
        exact: boolean;
      }
    | IsActiveMatchOptions;
};

@Component({
  selector: 'ffb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [TuiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    ShowHideDirective,
    HeaderSecondaryMenuComponent,
    TuiModeModule,
    DropdownMenuDirective,
    HeaderMainMenuMobileComponent,
    SearchFormComponent,
    NgClass,
    TuiButtonModule,
    RouterLink,
    LogoComponent,
    HeaderMainMenuDesktopComponent,
    TuiHostedDropdownModule,
    TuiDropdownModule,
    UserAvatarComponent,
    TuiSvgModule,
    TuiDataListModule,
    AsyncPipe,
  ],
})
export class HeaderComponent implements OnInit {
  private app = inject(AppService);
  private readonly destroy$ = inject(TuiDestroyService);
  private changeDetector = inject(ChangeDetectorRef);
  private router = inject(Router);
  private auth = inject(AuthenticationService);
  private langService = inject(LangService);
  private platformId = inject(PLATFORM_ID);
  private signedInUserService = inject(SignedInUserService);
  private menuService = inject(MenuService);
  readonly breakpointObserver = inject(BreakpointObserver);

  links: IMenu['attributes']['links'] = [];

  secondaryLinks: THeaderLink[] = [
    {
      routerLink: '/',
      labelTranslationKey: 'nav.home',
    },
    {
      routerLink: '/news',
      labelTranslationKey: 'nav.news',
    },
    {
      routerLink: '/newsletter',
      labelTranslationKey: 'nav.newsletter',
    },
    {
      routerLink: '/help',
      labelTranslationKey: 'nav.help',
    },
    {
      routerLink: '/contact',
      labelTranslationKey: 'nav.contact',
    },
    {
      href: 'https://www.ffbridge.boutique/',
      labelTranslationKey: 'nav.eShop',
    },
  ];

  signedInUser?: IMe | null;

  mobileMenuOpen = false;
  searchOpen = false;
  userMenuExpanded = false;

  constructor() {
    if (!isPlatformServer(this.platformId)) {
      this.signedInUserService.signedInUser$
        .pipe(takeUntil(this.destroy$))
        .subscribe((user) => {
          this.signedInUser = user;
          if (!user) {
            this.userMenuExpanded = false;
          }
          this.changeDetector.detectChanges();
        });
    }
  }

  ngOnInit(): void {
    this.getMenu();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.mobileMenuOpen = false;
        this.searchOpen = false;
      }
    });
  }

  getMenu() {
    this.langService.langChanges$
      .pipe(
        switchMap(() => this.menuService.menu$),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: ({
          data: {
            attributes: { links },
          },
        }) => {
          this.links = links;
          this.changeDetector.detectChanges();
          this.app.loading = false;
        },
        error: () => {
          this.app.loading = false;
        },
      });
  }

  toggleMobileMenu() {
    this.searchOpen = false;
    this.mobileMenuOpen = !this.mobileMenuOpen;
    this.changeDetector.detectChanges();
  }

  toggleSearch() {
    this.mobileMenuOpen = false;
    this.searchOpen = !this.searchOpen;
    this.changeDetector.detectChanges();
  }

  onClickOnUserMenuLink() {
    setTimeout(() => {
      this.userMenuExpanded = false;
    });
  }

  logout() {
    this.auth.signOut();
  }
}
