<div
  class="wrapper flex flex-column"
  *ffbContentAdornment="4; backgroundColor: 'light'"
>
  <div class="content-wrapper flex flex-column flex-auto">
    <div class="content flex flex-column flex-auto full-width mx-auto bg-white">
      <div class="flex flex-column flex-auto">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
