import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiLetModule } from '@taiga-ui/cdk';
import {
  TuiDataListModule,
  TuiExpandModule,
  TuiSvgModule,
} from '@taiga-ui/core';
import { IMenu } from '@lancelot-frontend/cms';
import {
  ExternalLinkDirective,
  UrlToRouterLinkPipe,
} from '@lancelot-frontend/core';

@Component({
  selector: 'ffb-header-main-menu-mobile-link',
  template: ` <ng-container *transloco="let t">
    @if (link.__component === 'navigation.link') {
      <!-- External link -->
      @if (link.link.startsWith('http') || link.openInNewTab === true) {
        <a
          [href]="link.link"
          [target]="link.openInNewTab !== false ? '_blank' : undefined"
          role="menuitem"
          tuiOption
          size="l"
        >
          <span class="tui-text_h4 color-primary">
            {{ link.label }}
          </span>
        </a>
      }
      <!-- Simple link -->
      @if (!link.link.startsWith('http') && link.openInNewTab !== true) {
        <a
          *tuiLet="link.link | urlToRouterLink as routerLink"
          [routerLink]="routerLink.routerLink"
          [queryParams]="routerLink.queryParams"
          [fragment]="routerLink.fragment"
          [routerLinkActiveOptions]="{ exact: false }"
          role="menuitem"
          routerLinkActive
          tuiOption
          size="l"
        >
          <span class="tui-text_h4 color-primary">
            {{ link.label }}
          </span>
        </a>
      }
    }
    <!-- Link with expand on click for sub-links -->
    @if (link.__component === 'navigation.submenu') {
      <a (click)="toggle()" role="menuitem" routerLinkActive tuiOption size="l">
        <span class="tui-text_h4">
          <span class="color-primary">
            {{ link.label }}
          </span>
          <tui-svg
            src="tuiIconChevronDown"
            class="icon"
            [class.upside-down]="expanded"
          ></tui-svg>
        </span>
      </a>
      <tui-expand [expanded]="expanded">
        <ng-template tuiExpandContent>
          @for (child of link.links; track child) {
            <!-- External link -->
            @if (child.link.startsWith('http') || child.openInNewTab === true) {
              <a
                [href]="child.link"
                [target]="child.openInNewTab !== false ? '_blank' : undefined"
                role="menuitem"
                tuiOption
                size="s"
              >
                {{ child.label }}
              </a>
            }
            <!-- Internal link -->
            @if (
              !child.link.startsWith('http') && child.openInNewTab !== true
            ) {
              <a
                *tuiLet="child.link | urlToRouterLink as routerLink"
                [routerLink]="routerLink.routerLink"
                [queryParams]="routerLink.queryParams"
                [fragment]="routerLink.fragment"
                [routerLinkActiveOptions]="{ exact: false }"
                role="menuitem"
                routerLinkActive
                tuiOption
                size="s"
              >
                {{ child.label }}
              </a>
            }
          }
        </ng-template>
      </tui-expand>
    }
  </ng-container>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLinkActive,
    RouterLink,
    TranslocoDirective,
    TuiDataListModule,
    TuiLetModule,
    TuiSvgModule,
    TuiExpandModule,
    UrlToRouterLinkPipe,
    ExternalLinkDirective,
  ],
})
export class HeaderMainMenuMobileLinkComponent {
  @Input() link!: IMenu['attributes']['links'][number];
  expanded = false;

  toggle() {
    this.expanded = !this.expanded;
  }
}

@Component({
  selector: 'ffb-header-main-menu-mobile',
  templateUrl: './header-main-menu-mobile.component.html',
  styleUrls: ['./header-main-menu-mobile.component.scss'],
  standalone: true,
  imports: [TuiDataListModule, HeaderMainMenuMobileLinkComponent],
})
export class HeaderMainMenuMobileComponent {
  @Input() links!: IMenu['attributes']['links'];
}
