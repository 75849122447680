<ng-container *transloco="let t">
  <div class="wrapper">
    <div class="content">
      <ng-content></ng-content>
    </div>
    @if (displayNavigation) {
      <div class="fixed top-0 left-0 p2">
        <button
          tuiIconButton
          appearance="outline"
          icon="tuiIconArrowLeft"
          shape="rounded"
          class="back-button"
          (click)="back()"
        ></button>
      </div>
    }
  </div>
</ng-container>
