import { AsyncPipe, NgStyle } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiHostedDropdownModule,
  TuiLinkModule,
  TuiScrollbarModule,
  TuiSvgModule,
} from '@taiga-ui/core';
import { TuiAccordionModule } from '@taiga-ui/kit';
import { PersonInformationPipe } from '@lancelot-frontend/components';
import { DisableLinkDirective } from '@lancelot-frontend/core';
import { AdminService } from '../../../../admin/admin.service';
import { AuthenticationService } from '../../../../authentication/authentication.service';
import { UserAvatarComponent } from '../../../../user/components/user-avatar/user-avatar.component';
import { SignedInUserService } from '../../../../user/user.service';
import { UserRightsService } from '../../../../user/user-rights.service';
import { AssistanceButtonComponent } from '../../../components/assistance-button/assistance-button.component';

@Component({
  selector: 'ffb-admin-layout-navigation',
  standalone: true,
  imports: [
    AsyncPipe,
    NgStyle,
    RouterLink,
    RouterLinkActive,
    TranslocoDirective,
    TuiLinkModule,
    TuiButtonModule,
    TuiSvgModule,
    TuiDataListModule,
    TuiHostedDropdownModule,
    TuiScrollbarModule,
    TuiAccordionModule,
    UserAvatarComponent,
    PersonInformationPipe,
    AssistanceButtonComponent,
    DisableLinkDirective,
  ],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  private auth = inject(AuthenticationService);
  private signedInUserService = inject(SignedInUserService);
  protected adminService = inject(AdminService);
  protected userRightsService = inject(UserRightsService);

  signedInPerson$ = this.signedInUserService.signedInPerson$;
  userMenuExpanded = false;

  logout() {
    this.auth.signOut();
  }
}
