<ng-container *transloco="let t">
  <div
    class="flex flex-column justify-between items-stretch min-screen-height"
    *ffbContentAdornment="4; backgroundColor: 'light'"
  >
    <div class="flex flex-0">
      @if (loadingContent === false) {
        <div class="width-fixer flex flex-auto flex-column mx-auto p2">
          <div class="flex flex-auto justify-center items-center p3">
            <a class="logo-container" routerLink="/">
              <ffb-logo class="center" tuiMode="onLight"></ffb-logo>
            </a>
          </div>
          @if (!app.isNativePlatform) {
            <a tuiLink routerLink="/" data-test-id="home-button">
              ← {{ t('nav.home') }}
            </a>
          }
        </div>
      }
    </div>
    <div>
      <div class="width-fixer m-auto p2">
        <ng-content></ng-content>
      </div>
    </div>
    <div class="flex-0 mt3 pt2"></div>
  </div>
  @if (!app.isNativePlatform) {
    <ffb-footer></ffb-footer>
  }
</ng-container>
