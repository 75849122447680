<ng-container *transloco="let t">
  <div class="full-height flex flex-column">
    @if (signedInPerson$ | async; as signedInPerson) {
      <div
        class="header flex flex-row items-center justify-between pt1 pl1 pb1"
      >
        <a routerLink="/">
          <tui-svg class="ffb-icon" src="/assets/images/ffb-icon.svg"></tui-svg>
        </a>
        <tui-hosted-dropdown
          tuiDropdownAlign="right"
          [content]="userMenu"
          [(open)]="userMenuExpanded"
        >
          <a
            tuiButton
            appearance="text"
            shape="rounded"
            size="s"
            class="lowercase"
          >
            <tui-svg
              src="tuiIconChevronDown"
              class="icon"
              [class.upside-down]="userMenuExpanded"
            ></tui-svg>
            <span class="mr1">
              {{ signedInPerson.firstName }}
            </span>
            <ffb-user-avatar
              [person]="signedInPerson"
              size="s"
            ></ffb-user-avatar>
          </a>
        </tui-hosted-dropdown>
      </div>

      <ng-template #userMenu>
        <tui-data-list class="user-dashboard-menu" role="menu">
          <!--  LINK TO USER DASHBOARD  -->
          <tui-opt-group>
            <a tuiOption role="menuitem" routerLink="/user/dashboard">
              <div>
                <p class="bold">
                  {{
                    signedInPerson
                      | personInformation: { lastName: true, firstName: true }
                  }}
                </p>
                {{ signedInPerson.ffbId }}
              </div>
            </a>
          </tui-opt-group>
          <!-- LOGOUT BUTTON -->
          <tui-opt-group>
            <a tuiOption role="menuitem" (click)="logout()">
              <tui-svg
                src="/assets/icon-set/logout.svg"
                class="color-primary mr1"
              ></tui-svg>
              {{ t('nav.user.logout') }}
            </a>
          </tui-opt-group>
        </tui-data-list>
      </ng-template>
    }

    <nav class="flex-auto">
      <tui-scrollbar class="max-full-height">
        @if (adminService.currentEntity$ | async; as currentEntity) {
          <tui-accordion [rounded]="false">
            <tui-accordion-item
              size="s"
              [showArrow]="false"
              [open]="false"
              [borders]="null"
              [routerLink]="['admin', currentEntity.ffbCode]"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              {{ t('admin.nav.home') }}
            </tui-accordion-item>
            <tui-accordion-item
              size="s"
              [showArrow]="false"
              [open]="false"
              [borders]="null"
              [routerLink]="['admin', currentEntity.ffbCode, 'members']"
              [queryParams]="
                currentEntity.type !== 'federation'
                  ? { entity: currentEntity.ffbCode }
                  : undefined
              "
              routerLinkActive="active"
            >
              {{ t('admin.nav.members') }}
            </tui-accordion-item>
            @if (userRightsService.canAccessUsers(currentEntity) | async) {
              <tui-accordion-item
                size="s"
                [showArrow]="false"
                [open]="false"
                [borders]="null"
                [routerLink]="['admin', currentEntity.ffbCode, 'users']"
                [queryParams]="{ hasPerson: false }"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{
                  matrixParams: 'ignored',
                  queryParams: 'ignored',
                  paths: 'subset',
                  fragment: 'ignored'
                }"
              >
                {{ t('admin.nav.users') }}
              </tui-accordion-item>
            }
            <!--            @if (-->
            <!--              userRightsService.canAccessEntitiesSettings(currentEntity) | async-->
            <!--            ) {-->
            <!--              <tui-accordion-item-->
            <!--                size="s"-->
            <!--                [borders]="null"-->
            <!--                [routerLink]="['admin', currentEntity.ffbCode, 'entities']"-->
            <!--                routerLinkActive-->
            <!--                #link="routerLinkActive"-->
            <!--                [class.active]="link.isActive"-->
            <!--                [open]="link.isActive"-->
            <!--                [disableLink]="true"-->
            <!--              >-->
            <!--                {{ t('admin.nav.entities') }}-->
            <!--                <ng-template tuiAccordionItemContent>-->
            <!--                  <div class="flex flex-column items-stretch">-->
            <!--                    <a-->
            <!--                      [routerLink]="[-->
            <!--                        'admin',-->
            <!--                        currentEntity.ffbCode,-->
            <!--                        'entities',-->
            <!--                        'create'-->
            <!--                      ]"-->
            <!--                      routerLinkActive="active"-->
            <!--                    >-->
            <!--                      {{ t('actions.create') }}-->
            <!--                      {{-->
            <!--                        t('resources.entity.withIndefiniteArticle', {-->
            <!--                          count: 1-->
            <!--                        })-->
            <!--                      }}-->
            <!--                    </a>-->
            <!--                  </div>-->
            <!--                </ng-template>-->
            <!--              </tui-accordion-item>-->
            <!--            }-->
            <!--              <tui-accordion-item-->
            <!--                size="s"-->
            <!--                [borders]="null"-->
            <!--                [routerLink]="['admin', currentEntity.ffbCode, 'competitions']"-->
            <!--                routerLinkActive-->
            <!--                #link="routerLinkActive"-->
            <!--                [class.active]="link.isActive"-->
            <!--                [open]="link.isActive"-->
            <!--                [disableLink]="true"-->
            <!--              >-->
            <!--                {{ t('admin.nav.competitions') }}-->
            <!--                <ng-template tuiAccordionItemContent>-->
            <!--                  <div class="flex flex-column items-stretch">-->
            <!--                    @if (-->
            <!--                      userRightsService.canAccessCompetitionSettings(-->
            <!--                        currentEntity-->
            <!--                      ) | async-->
            <!--                    ) {-->
            <!--                      <a-->
            <!--                        [routerLink]="[-->
            <!--                          'admin',-->
            <!--                          currentEntity.ffbCode,-->
            <!--                          'competitions',-->
            <!--                          'competitions'-->
            <!--                        ]"-->
            <!--                        routerLinkActive="active"-->
            <!--                      >-->
            <!--                        {{ t('admin.nav.competitions.competitions') }}-->
            <!--                      </a>-->
            <!--                      <a-->
            <!--                        [routerLink]="[-->
            <!--                          'admin',-->
            <!--                          currentEntity.ffbCode,-->
            <!--                          'competitions',-->
            <!--                          'divisions'-->
            <!--                        ]"-->
            <!--                        routerLinkActive="active"-->
            <!--                      >-->
            <!--                        {{ t('admin.nav.competitions.divisions') }}-->
            <!--                      </a>-->
            <!--                    }-->
            <!--                  </div>-->
            <!--                </ng-template>-->
            <!--              </tui-accordion-item>-->
          </tui-accordion>
        }
      </tui-scrollbar>
    </nav>

    <div class="px2 py1 center">
      <ffb-assistance-button icon="tuiIconHelpCircle"></ffb-assistance-button>
    </div>
  </div>
</ng-container>
