import { Component, Input, OnChanges, inject } from '@angular/core';
import { TuiSizeL, TuiSizeS } from '@taiga-ui/core';
import { TuiAvatarModule } from '@taiga-ui/kit';
import { IMe, IPerson } from '@lancelot-frontend/api';
import { MediaService } from '@lancelot-frontend/core';

@Component({
  selector: 'ffb-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  standalone: true,
  imports: [TuiAvatarModule],
})
export class UserAvatarComponent implements OnChanges {
  private mediaService = inject(MediaService);

  @Input() person?: IMe['person'] | IPerson | null;
  @Input() size?: TuiSizeL | TuiSizeS = 'l';

  avatarUrl: null | string = null;

  ngOnChanges() {
    if (this.person) {
      if (this.person.avatarUrl) {
        this.avatarUrl = this.mediaService.getAvatarUrl(this.person.avatarUrl);
      } else if (
        !(this.person.firstName ?? '')
          .concat(this.person.lastName ?? '')
          .replace(/ /g, '')
      ) {
        this.avatarUrl = 'tuiIconUser';
      } else {
        this.avatarUrl = null;
      }
    }
  }
}
