<ng-container *transloco="let t">
  <div class="mt3 mx2">
    <tui-hosted-dropdown
      [content]="dropdown"
      [(open)]="open"
      class="block full-width"
    >
      <h3 class="tui-text_h3 flex flex-row items-center justify-between gap1">
        {{
          t(context === 'results-list' ? 'nav.allResults' : 'nav.user.results')
        }}
        <tui-svg
          src="tuiIconChevronDown"
          class="icon fit-text"
          [class.upside-down]="open"
        ></tui-svg>
      </h3>

      <ng-template #dropdown>
        <tui-data-list class="user-dashboard-menu" role="menu">
          <a tuiOption routerLink="/user/results" role="menuitem">
            {{ t('nav.user.results') }}
          </a>
          <a tuiOption routerLink="/competitions/results" role="menuitem">
            {{ t('nav.allResults') }}
          </a>
        </tui-data-list>
      </ng-template>
    </tui-hosted-dropdown>
  </div>
</ng-container>
